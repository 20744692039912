<template>
  <div class="">
    <!--Filters-->
    <b-card no-body class="m-2">
      <b-card-header style="background-color: #c2aa8e" class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5 style="color: white">{{ $t("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: white" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body class="border">
        <b-row class="mt-2">

          <b-col cols="12" md="12" lg="3">
            <b-form-group :label="$t('Month')" label-for="type">
              <v-select
                  v-model="month"
                  :options="months"
                  label="label"
                  :reduce="(status) => status.value"
                  class="w-100"
                  :clearable="false"


              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="12" lg="3">
            <b-form-group :label="$t('Year')" label-for="type">
              <v-select
                  v-model="year"
                  :options="years"
                  label="label"
                  :reduce="(status) => status.value"
                  class="w-100"
                  :clearable="false"

              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
    <!--Data-->
    <b-card no-body class="m-2">
      <div class="card-header" style="overflow: hidden">
        <b-badge variant="primary" style="font-weight: bold" class="card-title  m-0">{{ $t('Real Estates') }}</b-badge>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>

        <!--Table-->
        <b-table
            thead-class="customHeader"
            ref="realEstate-table"
            striped
            hover
            responsive
            v-bind:items="realEstates"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ $t('Loading') }}...</strong>
            </div>
          </template>
          <template #cell(todoList)="data">
            <b-badge
                class="mb-1" variant="primary">{{ countDoneItems(data.item.todoList) }}</b-badge>
          </template>
          <template #cell(updatedDate)="data">
            {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
          </template>

          <template #cell(bookings)="data">
            <div class="night-container">
              <span class="night-count">{{ calculateTotalNights(data.item.id, data.value) }}</span>
              <i class="fas fa-circle" :style="{'color': calculateTotalNightsForColor(data.value)}"></i>
            </div>
          </template>


          <template #cell(address)="data">
            <div>
              <div style="white-space: nowrap">
                <span class="d-inline-block">{{ getFullAddress(data.item) }}</span>
              </div>
              <div style="margin-left: 5px">
                <strong>{{ $t('Zip') }}: </strong>{{ data.item.zip }}
              </div>
            </div>
          </template>
          <template #cell(realEstateTreasure)="data">
            <div v-if="data.value !== null" class="btn-group">
              <b-button v-if="data.value.bookingLink !== null" class="btn"
                        style="background-color: rgba(49,121,231,0.73)!important;" size="sm"
                        @click="copyToClipboard(data.value.bookingLink)"
                        v-b-tooltip.hover
                        title="Booking"
              >
                <i>B</i>
              </b-button>
              <b-button v-if="data.value.airbnbLink !== null" class="btn"
                        style="background-color: rgba(255,82,88,0.79)!important;" size="sm"
                        @click="copyToClipboard(data.value.airbnbLink)"


                        v-b-tooltip.hover
                        title="Airbnb"
              >
                <i>A</i>
              </b-button>
              <b-button v-if="data.value.expediaLink !== null" class="btn btn-warning" size="sm"
                        @click="copyToClipboard(data.value.expediaLink)"


                        v-b-tooltip.hover
                        title="Expedia"
              >
                <i>E</i>
              </b-button>
              <b-button v-if="data.value.treasureLink !== null"
                        style="background-color: rgba(199,166,128,0.71)!important;" class="btn" size="sm"
                        @click="copyToClipboard(data.value.treasureLink)"


                        v-b-tooltip.hover
                        title="Treasure Home"
              >
                <i>T</i>
              </b-button>

              <b-button v-if="data.value.agodaLink !== null" style="background-color: #2ecc71!important;" class="btn"
                        size="sm"
                        @click="copyToClipboard(data.value.agodaLink)"


                        v-b-tooltip.hover
                        title="Agoda"
              >
                <i>A</i>
              </b-button>
              <b-button v-if="data.value.vrboLink !== null" class="btn" style="background-color: #bfddea!important;"
                        size="sm"
                        @click="copyToClipboard(data.value.vrboLink)"


                        v-b-tooltip.hover
                        title="Vrbo"
              >
                <i>V</i>
              </b-button>
            </div>

          </template>
          <template #cell(realEstatePrices)="data">
            <div>
              {{ getPrices(data) }}
            </div>
          </template>
          <!--Cell status-->
          <template #cell(status)="data" class="p-0">
            <b-badge class="mb-1 d-block" :variant="statusVariants(data.value)">Status: {{ data.value }}</b-badge>

            <b-badge class="d-block"
                     :variant="data.item.advertise !=null && data.item.advertise===true ?'success':'danger'">
              Inserat: {{ getFeaturedStatus(data.item) }}
            </b-badge>
          </template>

          <template #cell(attachments)="data">
            <b-img
                v-bind=" { blank: (data.value == null || data.value[0] == null), blankColor: '#777', width: 50, height: 50,
                                src:(data.value != null && data.value[0] != null ? mergeSrcUrl('https://treasurehome.at',data.value[0]) : null)  }"
                blank-color="#475F7B"
                rounded
                alt="Rounded image"
                class="d-inline-block m-1"
            />
          </template>
        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ $t('Total') }}</strong> {{ realEstates.length }}</div>
        </div>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import VueClipboard from "vue-clipboard2";

export default {
  name: 'RealEstate',
  directives: {
    clipboard: VueClipboard,
  },
  components: {},
  data() {
    return {
      months: [],
      years: [],
      year: null,
      month: null,
      monthFilter: null,
      loading: true,
      amount: 1,
      realEstates: [],
      fields: [],
      filter: {
        like: {search: '', name: "", shortDescription: ""},
        eq: {type: "", realEstateArea: {}, featureStatus: null},
        lte: {realEstatePrices: {}},
        gte: {realEstatePrices: {}},
        in: {}
      },
      sort: {
        by: 'id',
        desc: true,
        direction: 'desc'
      },
      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      addressName: null,
      //Listeners
      listeners: {
        refresh: () => {
        }
      }
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    fields: {
      deep: true,
      handler() {
        localStorage["real-estates-fields"] = JSON.stringify(this.fields);
      }
    },
    selectedRealEstates: {
      handler() {
        this.$emit("selectedRealEstates", [...this.selectedRealEstates])
      }
    }
  },
  computed: {
    ...mapGetters('realEstates', ['allStatus', 'statusVariants', 'getPrices', 'getFeaturedStatus', "getFullAddress"]),
    getFilterDate() {
      if (this.monthFilter !== null) {
        return new Date(this.currentDate.getFullYear(), this.monthFilter - 1, 1);
      } else {
        // If no filter is set, calculate from the beginning of the month until today
        return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), 1);
      }
    },
    allRealEstateStatus() {
      return this.allStatus.map(status => ({
        value: status.value,
        text: status.text
      }))
    },

  },
  created() {
    let currentDate = new Date();
    this.month = currentDate.getMonth() + 1;
    this.year = currentDate.getFullYear();
    this.setFields();
    this.refresh()

    this.$root.$on('crm::lang-updated', this.setFields)
  },
  mounted() {
    this.getMonthOptions();

  },
  beforeDestroy() {
    this.$root.$off('crm::lang-updated', this.setFields)
  },
  methods: {
    ...mapActions('realEstates', ['getAllProperties', 'copyLinkSuccess']),
    getMonthOptions() {
      const monthObj = {
        value: null,
        label: this.$t('All Months')
      };
      this.months.push(monthObj);

      for (let i = 0; i < 12; i++) {
        const monthObj = {
          value: i + 1,
          label: new Date(0, i).toLocaleString('default', {month: 'long'})
        };
        this.months.push(monthObj);

      }

      const startYear = 2020;
      const currentDate = new Date();
      const endYear = currentDate.getFullYear();


      for (let year = startYear; year <= endYear; year++) {
        const yearObj = {
          value: year,
          label: year
        };
        this.years.push(yearObj);
      }


    },
    onLinkCopy: function (e) {
      console.log('test', e)
      this.copyLinkSuccess(e.text)
    },
    copyToClipboard(text) {
      // Create a temporary text area element to facilitate copying
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);

      // Select the text within the text area and copy it to the clipboard
      textArea.select();
      document.execCommand('copy');

      // Clean up by removing the temporary text area
      document.body.removeChild(textArea);
      this.copyLinkSuccess(textArea.value)

    },
    onError: function (e) {
      console.log(e)
      alert('Failed to copy the text to the clipboard')
    },
    setFields() {
      this.fields = [

        {
          key: 'attachments',
          label: this.$t('mainimage'),
          sortable: true,
          active: true,
        },
        {
          key: 'address',
          label: this.$t('Address'),
          sortable: true,
          active: true,
        },
        {
          key: 'todoList',
          label: this.$t('Todos'),
          sortable: true,
          active: true,
        },
        {
          key: 'status',
          label: this.$t('status'),
          sortable: true,
          active: true,
        },
        {
          key: 'bookings',
          label: this.$t('Utilization'),
          sortable: true,
          active: true,
        }, {
          key: 'realEstateTreasure',
          label: this.$t('Platform'),
          sortable: true,
          active: true,
        },

        {
          key: 'realEstatePrices',
          label: this.$t('price'),
          sortable: false,
          active: true,
        },
        {
          key: 'realEstateArea.totalSpace',
          label: this.$t('totalspace') + ' (m²)',
          sortable: false,
          active: true,
        },
        {
          key: 'realEstateArea.rooms',
          label: this.$t('Rooms'),
          sortable: true,
          active: true,
        },

      ];
    },
    calculateTotalNights(id, bookings) {
     /* if (id !== '6479a4cd7757d862227eee9d')
        return 'toobad'*/
      const currentDate = new Date();
      const year = this.year !== null ? this.year : currentDate.getFullYear();
      const month = this.month !== null ? this.month - 1 : currentDate.getMonth();

      console.log('Month:', month, 'Year:', year)
      const firstDayOfMonth = new Date(year, month, 1);
      const lastDayOfMonth = new Date(year, month + 1, 0);

      console.log('First day:', firstDayOfMonth, 'Last day:', lastDayOfMonth)
      let totalNights = 0;

      for (const booking of bookings) {
        const checkInDate = new Date(booking.checkInDate);
        const checkOutDate = new Date(booking.checkOutDate);


        if (checkInDate <= lastDayOfMonth && checkOutDate >= firstDayOfMonth) {
          const checkIn = Math.max(checkInDate, firstDayOfMonth);
          const checkOut = Math.min(checkOutDate, lastDayOfMonth);
          const nights = Math.ceil((checkOut - checkIn) / (1000 * 60 * 60 * 24));
          totalNights += nights;
          console.log('Booking:', checkInDate, 'to', checkOutDate,'is in range EventId:',booking.contractId);

          console.log('Nights in range:', nights);

          console.log('Total until now: ', totalNights, 'nights');
        }else
          console.log('Booking:', checkInDate, 'to', checkOutDate,'is NOT in range');

      }

      const totalDaysInMonth = lastDayOfMonth.getDate();
      const occupancyPercentage = ((totalNights / totalDaysInMonth) * 100).toFixed(2);
      if (id === '6479a4cd7757d862227eee9d') {
        console.log('Total Nights:', totalNights);
        console.log('Total Days in Month:', totalDaysInMonth);
        console.log('Occupancy Percentage:', occupancyPercentage);

      }


      return `${totalNights}/${totalDaysInMonth} Nächte (${occupancyPercentage}%)`;
    }

    ,
    calculateTotalNightsForColor(bookings) {
      const currentDate = new Date();
      const filterDate = new Date(
          this.year !== null ? this.year : currentDate.getFullYear(),
          this.month !== null ? this.month - 1 : currentDate.getMonth(),
          1
      );

      const endDate = new Date(
          this.year !== null ? this.year : currentDate.getFullYear(),
          this.month !== null ? this.month : currentDate.getMonth() + 1,
          0
      );


      let totalNights = 0;

      for (const booking of bookings) {
        const checkInDate = new Date(booking.checkInDate);
        const checkOutDate = new Date(booking.checkOutDate);

        if (checkInDate <= endDate && checkOutDate >= filterDate) {
          const checkIn = Math.max(checkInDate, filterDate);
          const checkOut = Math.min(checkOutDate, endDate);
          const nights = Math.ceil((checkOut - checkIn) / (1000 * 60 * 60 * 24));
          totalNights += nights;
        }
      }

      if (totalNights < 10) {
        /*
                console.log('<10', totalNights)
        */
        return '#ff7675';
      } else if (totalNights < 15) {
        /*
                console.log('<15', totalNights)
        */
        return '#fab1a0';
      } else if (totalNights < 20) {
        /*
                console.log('<20', totalNights)
        */
        return '#ffeaa7';
      } else if (totalNights < 25) {
        /*
                console.log('<25', totalNights)
        */
        return '#55efc4'; // Define your color for this range
      } else {
        /*
                console.log('>25', totalNights)
        */
        return '#47e708';
      }

    },
    countDoneItems(todo) {
      if (todo == null) return "0/0 jobs";
      const doneItems = todo.filter((item) => item.done === true);
      const totalItems = todo.length;
      return `${doneItems.length}/${totalItems} jobs`;
    },
    refresh() {
      let $this = this
      this.loading = true;
      this.getAllProperties({
        page: $this.page.currentPage,
        amount: $this.page.amount | 10,
        filter: $this.filter,
        sortcolumn: 'updatedDate',
        sortby: $this.sort.direction,
      })
          .then(this.onRealEstatesRetrieve, (error) => $this.error = error)
    },
    onRealEstatesRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.realEstates = data["RealEstates"];
      this.loading = false;
    },

    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(realEstate) {

      this.$router.push(`/realestates/edit/${realEstate.id}`);
    },
  }
}
</script>

<style scoped lang="scss">
.traffic-light {
  display: flex;
  align-items: center;
}

.red {
  color: red;
}

.orange {
  color: orange;
}

.green {
  color: green;
}

.table thead th, thead, th {
  background-color: #d6e9e8 !important;
}

.night-container {
  display: flex;
  align-items: center;
}

.night-count {
  margin-right: 5px; /* Add margin between the span and the icon */
}

.features-group .btn-group-toggle {
  width: 100%;

  i {
    font-size: 26px;
  }

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #f69c55;
  }
}

/*.features-group .btn {
  width: 100%;
  height: 100px;
  text-align: center;
  vertical-align: middle;
  //color:  !important;
}*/
</style>
